import logo from './logo.svg';
import './App.css';
import {useEffect, useState} from "react";
import {GET_INFO, GET_IP, PASSWORD} from "./const";
import Map from "./componets/Map";


function App() {
  const [myIp, setMyIp] = useState("");
  const [inputIp, setInputIp] = useState("");
  const [ipInfo, setIpInfo] = useState({});

  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);
  const getIp = async () => {
    const res = await fetch(GET_IP);
    const data = await res.json();
    console.log(data)
    setMyIp(data.ip);
    setInputIp(data.ip);
  }

  const getInfo = async () => {
    setLat(null);
    setLng(null);
    const res = await fetch(GET_INFO + "?ip=" + inputIp + "&password=" + PASSWORD);

    const data = await res.json();
    if (data.status === 404) {
      setIpInfo({"Error": "IP address is not correct."});
      return;
    }

    const loc = data.loc
    setLng(Number(loc.split(",")[1]));
    setLat(Number(loc.split(",")[0]));
    console.log(data)
    setIpInfo(data);
  }

  return (
    <div className="app mx-md-3 mx-lg-5">
      <div className="text-center my-5 ">
        <h1>IP Security Demo</h1>
      </div>

      <div className={"get-ip mx-3 my-3"}>
        <h2>Get My IP Address</h2>
        <button className="btn btn-primary" onClick={getIp}>Get My IP</button>
        {
          myIp !== "" &&
          <p className={"myip"}>Your IP Address: {myIp}</p>
        }
      </div>

      <div className={"ip-info  mx-3 my-3"}>
        <h2>IP Info</h2>
        {/*  input an ip address*/}
        <span>Input an ip address: </span>
        <input type="text" className={""} value={inputIp} onChange={
          event => {
            setInputIp(event.target.value)
          }
        }/>
        <button className={"btn btn-primary"} onClick={getInfo}>Get Info</button>
        {

          <div className={"ip-info"}>
            <pre>
              {
                JSON.stringify(ipInfo, null, 2)
                  .replaceAll("\"", "")
                  .replaceAll(",", " ")
                  .replaceAll("{", "")
                  .replaceAll("}", "")
              }
            </pre>
          </div>
        }
      </div>

      <div className={"google-map mx-3 my-3"}>
        {
          lng!= null && lat != null &&
          <Map lng1={lng} lat1={lat}/>
        }

      </div>

    </div>
  );
}

export default App;
